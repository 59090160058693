import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
} from "@mui/material";
import { StyledTableCell, StyledTableRow } from "../common/TableItems";

const SuggestionsTable = ({
  allFeatures = [],
  result,
  instance,
  selectedSuggestionIndex,
  onSelectedSugesstionIndex,
}) => {
  return (
    <TableContainer component={Paper} sx={{ maxHeight: "400px" }}>
      <Table>
        <TableHead>
          <StyledTableRow>
            <StyledTableCell
              padding="checkbox"
              key={"empty-checkbox-area"}
              size="small"
            ></StyledTableCell>
            {allFeatures.map((item, index) => (
              <StyledTableCell align="left" key={item} size="small">
                {item}
              </StyledTableCell>
            ))}
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {result.map((item, resultIndex) => (
            <StyledTableRow>
              <StyledTableCell
                padding="checkbox"
                key={resultIndex + "checkbox"}
              >
                <Checkbox
                  color="primary"
                  checked={selectedSuggestionIndex === resultIndex}
                  onChange={() => onSelectedSugesstionIndex(resultIndex)}
                />
              </StyledTableCell>
              {item.map((instanceItem, index) => (
                <StyledTableCell align="left" key={`${instanceItem}-${index}`}>
                  <Typography
                    sx={{
                      width: "fit-content",
                      fontSize: "14px",
                      background:
                        Number(instance[index]) != Number(instanceItem)
                          ? "#fde68a"
                          : "",
                    }}
                  >
                    {instanceItem}
                  </Typography>
                </StyledTableCell>
              ))}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SuggestionsTable;
