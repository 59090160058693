import { Button, Stack, Typography, useTheme } from "@mui/material";
import { Copy } from "phosphor-react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useAuth } from "src/hooks/use-auth";
import useNotifier, { NotificationType } from "src/hooks/use-notify";
import ProfileCardLayout from "./ProfileCardLayout";

const ApiKeyCard = () => {
  const theme = useTheme();
  const { notify } = useNotifier();
  const auth = useAuth();
  const user = auth?.user;

  return (
    <ProfileCardLayout title={"API Key"}>
      <Stack
        sx={{
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "space-between",
          gap: ".5rem",
          alignItems: "center",
        }}
      >
        <Typography>{user?.apiKey || "Not generated."}</Typography>
        {Boolean(user?.apiKey) && (
          <CopyToClipboard
            text={user?.apiKey}
            onCopy={() => notify(NotificationType.SUCCESS, "Copied!")}
            style={{ cursor: "pointer", color: theme.palette.success.main }}
          >
            <Button startIcon={<Copy size={20} />}>Copy</Button>
          </CopyToClipboard>
        )}
      </Stack>
    </ProfileCardLayout>
  );
};

export default ApiKeyCard;
