import { useMutation, useQuery } from "react-query";
import ApiClient from "src/axios";
import { GET_USERS_QUERY_KEY } from "src/utils/types";

const getAllUsers = async () => {
  const { data } = await ApiClient.get(`/api/admin/users`);
  return data;
};
export const useGetUsers = () => {
  return useQuery({
    queryKey: [GET_USERS_QUERY_KEY],
    queryFn: () => getAllUsers(),
  });
};


const deleteUser = async (id) => {
  const { data } = await ApiClient.delete(`/api/users/${id}`);
  return data;
}
export const useDeleteUser = () => {
  return useMutation({
    mutationFn: ({id}) => deleteUser(id),
  });
};