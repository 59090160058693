import { Grid } from "@mui/material";
import Breadcrumbs from "src/components/Breadcrumbs";
import ApiKeyCard from "src/components/profile/ApiKeyCard";
import DeleteProfileCard from "src/components/profile/DeleteProfileCard";
import ProfileInfoCard from "src/components/profile/ProfileInfoCard";

const Profile = () => {
  return (
    <div id="projects">
      <Breadcrumbs ancestors={[{ name: "Profile" }]} />
      <div className="page-content">
        <Grid container spacing={3} sx={{ pb: "5rem" }}>
          <Grid item xs={12} sm={6}>
            <ProfileInfoCard />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ApiKeyCard />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DeleteProfileCard />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Profile;
