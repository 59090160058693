import ProfileCardLayout from "./ProfileCardLayout";
import { Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import DeleteProfileDialog from "./DeleteProfileDialog";
import { useState } from "react";

const DeleteProfileCard = () => {
  const [deleteProfileOpen, setDeleteProfileOpen] = useState(false);
  
  return (
    <ProfileCardLayout title={"Delete Profile"}>
      <Stack gap={"1.25rem"}>
        <Typography>
          Deleting your profile will remove all your data from our system. This
          action cannot be undone.
        </Typography>
        <Button
          color="error"
          sx={{ width: "fit-content" }}
          variant="contained"
          size="medium"
          onClick={() => setDeleteProfileOpen(true)}
        >
          Delete
        </Button>
      </Stack>

      <DeleteProfileDialog
        open={deleteProfileOpen}
        onClose={() => setDeleteProfileOpen(false)}
      />
    </ProfileCardLayout>
  );
};

export default DeleteProfileCard;
