import { useEffect, useMemo, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Alert, Box, CircularProgress, Stack } from "@mui/material";
import { Line } from "react-chartjs-2";
import { useMultiTreeSession } from "src/hooks/use-multitree-session";
import { useGetSortedMultiTreeGenerations } from "src/hooks/sessions";
import { SESSION_REQ_INTERVAL, SessionStatus } from "src/utils/types";
import { getErrorMsg } from "src/utils/Utils";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const colors = [
  "#0101ff",
  "#2ee09a",
  "#ff684d",
  "#E8E288",
  "#7DCE82",
  "#3CDBD3",
  "#3CDBD3",
  "#E3D8F1",
  "#726DA8",
  "#A0D2DB",
];

const DoesNotHaveMetricsMsg = {
  TEST_SESSION:
    "Test sessions do not have generations. Please run a training session to view metrics evolution.",
  MULTI_TREE:
    "Multi-tree sessions do not have generations. Please run a single-tree session to view metrics evolution.",
  NOT_OPERATED:
    "Session is not operated yet. Please run the session to view metrics evolution.",
};

function MetricsEvolution() {
  const [generationResults, setGenerationResults] = useState([]);
  const [initialloading, setInitialloading] = useState(true);
  const {
    sessionId,
    isTrainSession,
    isTestSession,
    selectedSession,
    sessionStatus,
    session,
  } = useMultiTreeSession();

  const isMultiTree = Boolean(session?.algorithm?.multiTree);
  const noGenerationExist = generationResults.length === 0;
  const isSessionOperated = sessionStatus != SessionStatus.CREATED;
  const isRunningSession = sessionStatus === SessionStatus.RUNNING;

  const {
    mutateAsync: getSortedTrainResults,
    isError,
    error,
    isSuccess,
  } = useGetSortedMultiTreeGenerations({
    sessionId: sessionId,
    enabled: Boolean(isTrainSession),
  });

  useEffect(() => {
    if (!selectedSession || !isTrainSession || isMultiTree) return;
    let interval;

    const fetchData = async () => {
      try {
        const data = await getSortedTrainResults({
          sortInfo: undefined,
          paginationInfo: undefined,
        });
        setGenerationResults(data?.results || []);
      } catch (error) {
        console.error("Error fetching generations:", error);
      } finally {
        setInitialloading(false);
      }
    };

    // Fetch data immediately when sort, page or interval changes
    fetchData();

    if (isRunningSession) {
      interval = setInterval(() => {
        fetchData();
      }, SESSION_REQ_INTERVAL);
    }

    return () => clearInterval(interval);
  }, [sessionId, isTrainSession, isMultiTree, isRunningSession]);

  const metricFields = useMemo(() => {
    if (generationResults.length === 0) {
      return [];
    }

    let fields = new Set(["fitness", "size"]);
    generationResults.forEach((model) => {
      Object.keys(model.other || {}).forEach((key) => {
        if (model.other[key] && typeof model.other[key] == "number") {
          fields.add(key);
        }
      });
    });

    return Array.from(fields);
  }, [generationResults]);

  const data = {
    labels: (generationResults || []).map((item) => item.generation),
    datasets: [
      ...metricFields.map((label, i) => {
        return {
          label: `${label}`,
          data: (generationResults || []).map((item) =>
            (item || {}).hasOwnProperty(label)
              ? item[label]
              : (item?.other || {}).hasOwnProperty(label)
                ? item.other[label]
                : undefined
          ),
          borderColor: colors[i % colors.length],
          backgroundColor: colors[i % colors.length],
        };
      }),
    ],
  };

  return (
    <div id="metrics-line-container">
      {isTestSession || isMultiTree || !isSessionOperated ? (
        <Box sx={{ p: "1rem" }}>
          <Alert severity="warning">
            {isTestSession
              ? DoesNotHaveMetricsMsg.TEST_SESSION
              : isMultiTree
                ? DoesNotHaveMetricsMsg.MULTI_TREE
                : DoesNotHaveMetricsMsg.NOT_OPERATED}
          </Alert>
        </Box>
      ) : (
        <Box sx={{ py: "1rem" }}>
          {initialloading ? (
            <Stack
              sx={{
                alignItems: "center",
                mt: 3,
              }}
            >
              <CircularProgress size={24} thickness={5} />
            </Stack>
          ) : isError ? (
            <Alert severity="error">{getErrorMsg(error)}</Alert>
          ) : noGenerationExist ? (
            <Box sx={{ p: "1rem" }}>
              <Alert severity="info">
                <b>No generations found</b>
                <br />
                Make sure your algorithm outputs generations.csv
              </Alert>
            </Box>
          ) : (
            <Line options={options} data={data} />
          )}
        </Box>
      )}
    </div>
  );
}

export default MetricsEvolution;

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",
      align: "start",
    },
    zoom: {
      zoom: {
        // wheel: {
        //   enabled: true,
        // },
        pinch: {
          enabled: true,
        },
        mode: "xy",
      },
      pan: {
        enabled: true,
        mode: "xy",
      },
    },
  },
  scales: {
    y: {
      title: {
        display: true,
        text: "value",
      },
    },
    x: {
      title: {
        display: true,
        text: "generation",
      },
    },
  },
};
