import React, { useEffect } from "react";
import { HashRouter, Routes, Route, Navigate } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Toaster } from "sonner";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";

import "react-mosaic-component/react-mosaic-component.css";
import "./scss/main.scss";

import { ProvideAuth } from "./hooks/use-auth.js";
import Sessions from "./pages/Sessions";
import Bookmarked from "./pages/Bookmarked";
import Overview from "./pages/Overview";
import Datasets from "./pages/Datasets";
import Configs from "./pages/Config";
import Whatif from "./pages/Whatif";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./theme/index.js";
import { MultitreeSessionPageProvider } from "./hooks/use-multitree-session.jsx";
import RequireAuth from "./layouts/RequireAuth";
import PublicRoute from "./layouts/PublicRoute";
import Loading from "./components/Loading";
import AxiosErrorHandler from "./layouts/AxiosErrorHandler.jsx";
import Initial from "./pages/Initial.jsx";
import CounterFactual from "./pages/CounterFactual";
import MultiTreeSession from "./pages/MultiTreeSession";
import FeatureImportance from "./pages/FeatureImportance";
import Profile from "./pages/Profile";

// Pages
const Login = React.lazy(() => import("./pages/Login"));
const Register = React.lazy(() => import("./pages/Register"));
const Page404 = React.lazy(() => import("./pages/Page404"));
const Page500 = React.lazy(() => import("./pages/Page500"));
const Projects = React.lazy(() => import("./pages/Projects"));
const Users = React.lazy(() => import("./pages/Users"));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  // program has screen size 'ResizeObserver loop' overlay warning when running on docker container.
  // it removes the overlay
  useEffect(() => {
    window.addEventListener("error", (e) => {
      if (e.message.startsWith("ResizeObserver loop")) {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Toaster position="top-center" richColors />
      <QueryClientProvider client={queryClient}>
        <DndProvider backend={HTML5Backend}>
          <ProvideAuth>
            <AxiosErrorHandler>
              <HashRouter>
                <React.Suspense fallback={<Loading />}>
                  <Routes>
                    <Route path="/" exact element={<Initial />} />
                    <Route
                      element={
                        <RequireAuth>
                          <Users />
                        </RequireAuth>
                      }
                      exact
                      path="/users"
                      name="Users"
                    />
                    <Route
                      exact
                      home
                      path="/projects"
                      element={
                        <RequireAuth>
                          <Projects />
                        </RequireAuth>
                      }
                      name="Projects"
                    />
                    <Route
                      element={
                        <RequireAuth>
                          <Overview />
                        </RequireAuth>
                      }
                      exact
                      path="/project/:id"
                      name="Project"
                    />
                    <Route
                      element={
                        <RequireAuth>
                          <Profile />
                        </RequireAuth>
                      }
                      exact
                      path="/profile"
                      name="Profile"
                    />
                    <Route
                      element={
                        <RequireAuth>
                          <Overview />
                        </RequireAuth>
                      }
                      exact
                      path="/project/:id/overview"
                      name="Project"
                    />
                    <Route
                      element={
                        <RequireAuth>
                          <Datasets />
                        </RequireAuth>
                      }
                      exact
                      path="/project/:id/datasets"
                      name="Project"
                    />
                    <Route
                      element={
                        <RequireAuth>
                          <Configs />
                        </RequireAuth>
                      }
                      exact
                      path="/project/:id/configs"
                      name="Project"
                    />
                    <Route
                      element={
                        <RequireAuth>
                          <Bookmarked />
                        </RequireAuth>
                      }
                      exact
                      path="/project/:id/bookmarked"
                      name="Project"
                    />
                    <Route
                      element={
                        <RequireAuth>
                          <Sessions />
                        </RequireAuth>
                      }
                      exact
                      path="/project/:id/sessions"
                      name="Project"
                    />
                    <Route
                      element={
                        <RequireAuth>
                          <MultitreeSessionPageProvider>
                            <MultiTreeSession />
                          </MultitreeSessionPageProvider>
                        </RequireAuth>
                      }
                      exact
                      path="/project/:id/sessions/multi-tree/:sessionId"
                      name="Session"
                    />
                    <Route
                      element={
                        <RequireAuth>
                          <Whatif />
                        </RequireAuth>
                      }
                      exact
                      path="/project/:id/whatif"
                      name="Project"
                    />
                    <Route
                      element={
                        <RequireAuth>
                          <CounterFactual />
                        </RequireAuth>
                      }
                      exact
                      path="/project/:id/counterfactual"
                      name="Project"
                    />
                    <Route
                      element={
                        <RequireAuth>
                          <FeatureImportance />
                        </RequireAuth>
                      }
                      exact
                      path="/project/:id/feature-importance"
                      name="Project"
                    />
                    <Route
                      element={
                        <PublicRoute>
                          <Login />
                        </PublicRoute>
                      }
                      exact
                      path="/login"
                      name="Login Page"
                    />
                    <Route
                      element={
                        <PublicRoute>
                          <Register />
                        </PublicRoute>
                      }
                      exact
                      path="/register"
                      name="Register Page"
                    />
                    <Route
                      element={<Page500 />}
                      exact
                      path="/500"
                      name="Page 500"
                    />
                    <Route
                      element={<Page404 />}
                      path="/404"
                      name="Page 404"
                    />
                    <Route
                      element={<Navigate to={'/'} />}
                      path="*"
                      name="*"
                    />
                  </Routes>
                </React.Suspense>
              </HashRouter>
            </AxiosErrorHandler>
          </ProvideAuth>
          <ReactQueryDevtools />
        </DndProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
};

export default App;
