import {
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Paper,
  Input,
  Box,
  useTheme,
} from "@mui/material";
import { StyledTableCell, StyledTableRow } from "../common/TableItems";
import { Fragment } from "react";

const ChangeDataInstance = ({
  instance = [],
  targetLabel,
  changedInstance = [],
  dataHeaders,
  onChangeInstance,
}) => {
  const theme = useTheme();
  return (
    <Fragment>
      <Box
        sx={{
          mb: ".4rem",
          mt: "2rem",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "600",
          }}
        >
          Change Data Instance
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
          }}
        >
          Target Field: "
          <b>
            <i>{targetLabel}</i>
          </b>
          ""
        </Typography>
      </Box>
      <TableContainer component={Paper} sx={{ maxHeight: "500px" }}>
        <Table>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell
                padding="checkbox"
                key={"empty-checkbox-area"}
                sx={{ width: "150px" }}
              >
                features:
              </StyledTableCell>
              {dataHeaders.map((item, index) => {
                return (
                  <StyledTableCell align="left" key={index}>
                    {item}
                  </StyledTableCell>
                );
              })}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            <StyledTableRow hover key={"actual-row"}>
              <StyledTableCell
                padding="checkbox"
                key={"empty-checkbox-area"}
                sx={{ width: "150px" }}
              >
                <b>actual data:</b>
              </StyledTableCell>
              {(instance || []).map((item, dataIndex) => {
                return (
                  <StyledTableCell align="left" key={dataIndex}>
                    {item}
                  </StyledTableCell>
                );
              })}
            </StyledTableRow>
            <StyledTableRow hover key={"changed-row"}>
              <StyledTableCell
                padding="checkbox"
                key={"empty-checkbox-area"}
                sx={{ width: "150px" }}
              >
                <b>analyse data</b>
              </StyledTableCell>
              {changedInstance.map((item, dataIndex) => {
                return (
                  <StyledTableCell align="left" key={dataIndex}>
                    <Input
                      style={{
                        width: "100%",
                      }}
                      value={item}
                      onChange={(ev) => onChangeInstance(ev, dataIndex)}
                    />
                  </StyledTableCell>
                );
              })}
            </StyledTableRow>
          </TableBody>
          <caption style={{ color: theme.palette.warning.dark, textAlign: 'center' }} >
            Analyse data fields are editable. You can change it by clicking
            related data field.
          </caption>
        </Table>
      </TableContainer>
    </Fragment>
  );
};

export default ChangeDataInstance;
