import { useState } from "react";
import { CaretDoubleLeft } from "phosphor-react";
import { SessionTypes } from "src/utils/types";
import { useMultiTreeSession } from "src/hooks/use-multitree-session";
import MosaicPanel from "src/components/common/MosaicPanel";
import TrainingLogs from "./TrainingLogs";
import GenerationsTable from "./GenerationsTable";
import MetricsEvolution from "./MetricsEvolution";
import TrainingSidebar from "./TrainingSidebar";

const Training = () => {
  const { session } = useMultiTreeSession();

  const [expanded, setExpanded] = useState(false);
  const [tilesLayout, setTilesLayout] = useState({
    currentNode: {
      direction: "column",
      first: Boolean(session?.properties?.commandType === SessionTypes.TEST)
        ? "test_results"
        : "generations",
      second: {
        direction: "row",
        first: "metrics",
        second: "logs",
      },
      splitPercentage: 50,
    },
  });

  const handleChangeMosaicLayout = (newCurrentNode) => {
    setTilesLayout({
      ...tilesLayout,
      currentNode: newCurrentNode,
    });
  };

  const elementMap = {
    generations: <GenerationsTable />,
    test_results: <GenerationsTable />,
    metrics: <MetricsEvolution />,
    logs: <TrainingLogs />,
  };

  return (
    <div className="model-tab" id="model-training-section">
      <div id="training-left" className={expanded ? "minimized" : ""}>
        <TrainingSidebar />
      </div>

      <div id="training-right">
        <MosaicPanel
          tilesLayout={tilesLayout}
          onChangeLayout={handleChangeMosaicLayout}
          mosaicId="training"
          elementMap={elementMap}
        />
        <div id="expand-button-container">
          <button
            id="expand-button"
            onClick={() => setExpanded(!expanded)}
            className={expanded ? "expanded" : ""}
          >
            <CaretDoubleLeft size={16} weight="bold" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Training;
