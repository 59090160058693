import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "src/hooks/use-auth";
import useNotifier, { NotificationType } from "src/hooks/use-notify";
import { useDeleteUser } from "src/hooks/users";
import { getErrorMsg } from "src/utils/Utils";

const DeleteProfileDialog = ({ open, onClose }) => {
  const { notify } = useNotifier();
  const theme = useTheme();
  const auth = useAuth();
  const user = auth?.user;
  const navigate = useNavigate();
  
  const { mutateAsync: deleteUser, isLoading } = useDeleteUser();

  const handleSubmit = async () => {
    deleteUser({ id: user?.id })
      .then(() => {
        notify(NotificationType.SUCCESS, "Profile deleted");
        auth.signout();
        navigate("/login");
      })
      .catch((err) => {
        notify(NotificationType.ERROR, getErrorMsg(err));
      });
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={onClose}>
      <DialogTitle sx={{ m: 0, p: 2 }} variant="h6">
        {`Are you sure?`}
      </DialogTitle>
      <DialogContent>This action cannot be undone.</DialogContent>
      <DialogActions>
        <Button onClick={onClose}>No</Button>
        <Button
          variant="contained"
          color="error"
          startIcon={
            isLoading ? (
              <CircularProgress
                size={16}
                thickness={5}
                sx={{ color: theme.palette.primary.contrastText }}
              />
            ) : undefined
          }
          onClick={handleSubmit}
        >
          Yes, delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteProfileDialog;
