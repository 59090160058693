import {
  Alert,
  AlertTitle,
  Button,
  Grid,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { PencilSimple, Play, Stop } from "phosphor-react";
import { Fragment, useState } from "react";
import Status from "src/components/common/Status";
import CreateSessionModal from "src/components/session/CreateSessionModal";
import AlgorithmBasedExpressionsDialog from "src/components/session/select-algo-base-expression/AlgorithmBasedExpressionsDialog";
import { AlgorithmBasedExpressionTabs } from "src/components/session/utils";
import { usePauseSession, useRunSession } from "src/hooks/sessions";
import { useMultiTreeSession } from "src/hooks/use-multitree-session";
import useNotifier, { NotificationType } from "src/hooks/use-notify";
import {
  DialogMode,
  SESSION_REQ_INTERVAL,
  SessionStatus,
} from "src/utils/types";
import { getErrorMsg } from "src/utils/Utils";

const TrainingSidebar = () => {
  const theme = useTheme();
  const { notify } = useNotifier();
  const {
    sessionId,
    session,
    sessionStatus,
    setSessionStatus,
    editing,
    running,
    setRunning,
    setIntervalMs,
    isTestSession,
    isTrainSession,
  } = useMultiTreeSession();

  const [expressionsDialogOpen, setExpressionsDialogOpen] = useState(false);
  const [updateSessionDialogOpen, setUpdateSessionDialogOpen] = useState(false);

  const { mutateAsync: runSessionMutation } = useRunSession();
  const { mutateAsync: pauseSessionMutation } = usePauseSession();

  const runSession = () => {
    runSessionMutation({ sessionId: sessionId })
      .then(() => {
        setRunning(true);
        setSessionStatus(SessionStatus.RUNNING);
        setIntervalMs(SESSION_REQ_INTERVAL);
      })
      .catch((error) => {
        notify(NotificationType.ERROR, getErrorMsg(error));
      });
  };

  const pauseSession = () => {
    pauseSessionMutation({ sessionId: sessionId })
      .then(() => {
        setRunning(false);
        setSessionStatus("KILLED");
        setIntervalMs(SESSION_REQ_INTERVAL);
      })
      .catch((error) => {
        notify(NotificationType.ERROR, getErrorMsg(error));
      });
  };

  return (
    <Fragment>
      <div id="top-buttons">
        <button
          id="session-edit-button"
          className="icon-button"
          disabled={editing || running}
          type="button"
          onClick={() => setUpdateSessionDialogOpen(true)}
        >
          <PencilSimple size={18} weight="bold" />
          <span>Edit</span>
        </button>
        {running ? (
          <button
            id="session-stop-button"
            className="icon-button"
            type="button"
            onClick={() => pauseSession()}
          >
            <Stop
              size={18}
              color={theme.palette.primary.contrastText}
              weight="bold"
            />
            <span>Stop</span>
          </button>
        ) : (
          <button
            id="session-run-button"
            className="icon-button"
            disabled={editing || running}
            type="button"
            onClick={() => runSession()}
          >
            <Play
              size={18}
              color={theme.palette.primary.contrastText}
              weight="bold"
            />
            <span>Start</span>
          </button>
        )}
      </div>

      <form className="session-form">
        <div className="input-container">
          <Stack gap={2} py={2}>
            <Stack>
              <Typography variant="body2" fontWeight={700}>
                Dataset
              </Typography>
              <Typography>{session?.dataset?.name || "-"}</Typography>
            </Stack>
            <Stack>
              <Typography variant="body2" fontWeight={700}>
                Algorithm
              </Typography>
              <Typography>{session?.algorithm?.name || "-"}</Typography>
            </Stack>
            {isTrainSession && (
              <Stack>
                <Typography variant="body2" fontWeight={700}>
                  Configuration
                </Typography>
                <Typography>{session?.configuration?.name || "-"}</Typography>
              </Stack>
            )}
            {isTestSession && (
              <Stack>
                <Typography variant="body2" fontWeight={700}>
                  Expressions
                </Typography>
                <Alert sx={{ marginTop: ".25rem" }} severity="info">
                  <Typography>
                    <b>
                      <i>
                        {(session?.customData || []).length} expression exists.
                      </i>
                    </b>
                  </Typography>
                  <Typography variant="body2">
                    In order to list expressions, click details button.
                  </Typography>
                  <Button
                    variant="outlined"
                    sx={{ height: "35px", marginTop: ".5rem" }}
                    onClick={() => setExpressionsDialogOpen(true)}
                  >
                    Details
                  </Button>
                </Alert>
              </Stack>
            )}
          </Stack>
        </div>
      </form>

      {sessionStatus && (
        <Grid container>
          <Grid
            item
            xs={12}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Status status={sessionStatus} />
          </Grid>
        </Grid>
      )}

      <Grid container spacing={1} mt={2}>
        {(session?.errorMsgs || []).map((errMsg) => (
          <Grid item xs={12}>
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {errMsg}
            </Alert>
          </Grid>
        ))}
      </Grid>

      {updateSessionDialogOpen && (
        <CreateSessionModal
          open={true}
          existingSession={session}
          mode={DialogMode.UPDATE}
          onClose={() => setUpdateSessionDialogOpen(false)}
        />
      )}

      {expressionsDialogOpen && (
        <AlgorithmBasedExpressionsDialog
          open={true}
          mode={DialogMode.VIEW}
          defaultTab={AlgorithmBasedExpressionTabs.ONLY_SELECTEDS}
          selectedPopulations={session?.customData}
          algorithm={session?.algorithm}
          onClose={() => setExpressionsDialogOpen(false)}
        />
      )}
    </Fragment>
  );
};

export default TrainingSidebar;
