import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SignOut, User, UserCircle } from "phosphor-react";
import { useAuth } from "../hooks/use-auth";
import logo from "../assets/TRUSTAI-logo.png";
import {
  Avatar,
  Button,
  Divider,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import StyledMenu from "./common/StyledMenu";

function Navbar() {
  const theme = useTheme();
  let navigate = useNavigate();
  let auth = useAuth();
  const user = auth?.user;
  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState(undefined);

  const handleOpenUserMenu = (event) => {
    setUserMenuAnchorEl(event.currentTarget);
  };

  return (
    <div id="navbar">
      <img
        onClick={() => {
          navigate("/projects");
        }}
        id="navbar-logo"
        src={logo}
        alt="TRUSTAI Logo"
        style={{ cursor: "pointer" }}
      />
      <ul id="navbar-items">
        <li
          onClick={() => {
            navigate("/projects");
          }}
        >
          Projects
        </li>
        {user?.isAdmin && (
          <li
            onClick={() => {
              navigate("/users");
            }}
          >
            Users
          </li>
        )}
        <li id="signout-container">
          <IconButton
            onClick={handleOpenUserMenu}
            color="inherit"
            sx={{ padding: "0" }}
          >
            {user?.firstname ? (
              <div style={{ margin: "7px" }}>
                <Avatar
                  sx={{
                    width: "2rem",
                    height: "2rem",
                    backgroundColor: theme.palette.info.main,
                    fontWeight: "600",
                    fontSize: "1.125rem",
                    transition: "all ease .3s",
                    "&:hover": {
                      backgroundColor: theme.palette.info.dark,
                    },
                  }}
                >
                  {(user?.firstname[0] || "").toUpperCase()}
                </Avatar>
              </div>
            ) : (
              <UserCircle />
            )}
          </IconButton>
        </li>
      </ul>

      <StyledMenu
        id="restart-options-menu"
        anchorEl={userMenuAnchorEl}
        open={Boolean(userMenuAnchorEl)}
        onClose={() => setUserMenuAnchorEl(undefined)}
      >
        <Stack sx={{ padding: ".75rem 1.25rem", gap: "1rem" }}>
          <Button
            sx={{
              padding: ".5rem 0",
              width: "100%",
            }}
            onClick={() => {
              setUserMenuAnchorEl(undefined);
              navigate("/profile");
            }}
          >
            <User size={20} weight="bold" color={theme.palette.grey[600]} />
            <Typography color={theme.palette.grey[600]} sx={{ ml: ".5rem" }}>
              Profile
            </Typography>
          </Button>

          <Divider />

          <Button
            color="error"
            sx={{
              padding: ".5rem 0",
              width: "100%",
            }}
            onClick={() => {
              auth.signout();
              navigate("/login");
            }}
          >
            <SignOut size={20} weight="bold" color={theme.palette.error.main} />
            <Typography color={theme.palette.error.main} sx={{ ml: ".5rem" }}>
              Logout
            </Typography>
          </Button>
        </Stack>
      </StyledMenu>
    </div>
  );
}

export default Navbar;
