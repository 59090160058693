import { Card, Typography } from "@mui/material";

const ProfileCardLayout = ({ title, children }) => {
  return (
    <Card
      sx={{
        padding: "1.5rem",
        display: "flex",
        flexDirection: "column",
        gap: "1.5rem",
      }}
    >
      <Typography variant="h6" sx={{ fontWeight: "600" }}>
        {title}
      </Typography>
      {children}
    </Card>
  );
};

export default ProfileCardLayout;
