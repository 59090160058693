import { Box, Divider, Stack, Typography, useTheme } from "@mui/material";
import { CheckCircle, XCircle } from "phosphor-react";
import { useAuth } from "src/hooks/use-auth";
import ProfileCardLayout from "./ProfileCardLayout";

const ProfileInfoCard = () => {
  const theme = useTheme();
  const auth = useAuth();
  const user = auth?.user;

  return (
    <ProfileCardLayout title={"Profile Informations"}>
      <Stack gap={".5rem"}>
        <InfoItem title={"Name"} value={user?.firstname || "-"} />
        <Divider />
        <InfoItem title={"Email"} value={user?.email || "-"} />
        <Divider />
        <InfoItem
          title={"Admin"}
          value={
            Boolean(user?.isAdmin) ? (
              <CheckCircle color={theme.palette.success.main} size={20} />
            ) : (
              <XCircle color={theme.palette.error.main} size={20} />
            )
          }
        />
      </Stack>
    </ProfileCardLayout>
  );
};

export default ProfileInfoCard;

const InfoItem = ({ title, value }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: ".75rem",
        paddingY: ".25rem",
      }}
    >
      <Typography fontWeight={"600"}>{title}:</Typography>
      {typeof value === "string" ? <Typography>{value}</Typography> : value}
    </Box>
  );
};
