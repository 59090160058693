import { useState, useEffect } from "react";
import {
  useBookmarkMultitreeSolution,
  useGetMultitreeSessionBookmarks,
  useRemoveBookmarkMultitreeSolution,
} from "../../../hooks/sessions";
import { Stack } from "@mui/material";
import { SessionStatus, SortDirection } from "src/utils/types";
import { useMultiTreeSession } from "src/hooks/use-multitree-session";
import { getErrorMsg } from "src/utils/Utils";
import ComparisonTablePagination from "./ComparisonTablePagination";
import useNotifier, { NotificationType } from "src/hooks/use-notify";
import MultiTreeTable from "./comparison-table/MultiTreeTable";
import SingleTreeTable from "./comparison-table/SingleTreeTable";
import MosaicPanelEmptyMsg from "../MosaicPanelEmptyMsg";

function ComparisonTable() {
  const { notify } = useNotifier();
  const {
    filteredSolutions,
    sessionStatus,
    sessionId,
    comparisonSort,
    setComparisonSort,
    session,
  } = useMultiTreeSession();
  const [bookmarkedItems, setBookmarkedItems] = useState(new Set());

  const isMultiTreeSession = Boolean(session?.algorithm?.multiTree);

  const isFailedOrFinished =
    sessionStatus === SessionStatus.FAILED ||
    sessionStatus === SessionStatus.FINISHED;

  const { data: multitreeBookmarksData } =
    useGetMultitreeSessionBookmarks(sessionId);

  const { mutateAsync: bookmark } = useBookmarkMultitreeSolution();
  const { mutateAsync: removeBookmark } = useRemoveBookmarkMultitreeSolution();

  const changeBookmarkedItems = (results = []) => {
    let bookmarkedIds = new Set();
    results.map((item) => {
      bookmarkedIds.add(item.id);
    });
    setBookmarkedItems(bookmarkedIds);
  };

  useEffect(() => {
    if (multitreeBookmarksData) {
      changeBookmarkedItems(multitreeBookmarksData.results || []);
    }
  }, [multitreeBookmarksData]);

  const changeSortField = (field) => {
    if (field === comparisonSort.key) {
      setComparisonSort(() => ({
        key: field,
        order:
          comparisonSort.order === SortDirection.ASC
            ? SortDirection.DESC
            : SortDirection.ASC,
      }));
    } else {
      setComparisonSort((prev) => ({ ...prev, key: field }));
    }
  };

  const handleBookmark = (resultId, modelId) => {
    bookmark({ resultId, modelId })
      .then((data) => {
        changeBookmarkedItems(data.results || []);
      })
      .catch((err) => {
        notify(NotificationType.ERROR, getErrorMsg(err));
      });
  };

  const handleRemoveBookmark = (modelId) => {
    if (!multitreeBookmarksData) {
      notify(NotificationType.ERROR, "Could not remove bookmarked.");
      return;
    }
    removeBookmark({ bookmarkModelId: multitreeBookmarksData.id, modelId })
      .then(() => {
        let bookmarkeds = new Set(Array.from(bookmarkedItems));
        bookmarkeds.delete(modelId);
        setBookmarkedItems(bookmarkeds);
      })
      .catch((err) => {
        notify(NotificationType.ERROR, getErrorMsg(err));
      });
  };

  if (!filteredSolutions || !(filteredSolutions.length > 0)) {
    return isFailedOrFinished ? (
      <MosaicPanelEmptyMsg
        title={"No populations found"}
        desc={"make sure your algorithm outputs populations.csv"}
      />
    ) : (
      <div className="tile-no-solutions">No solutions found</div>
    );
  }

  return (
    <Stack sx={{ width: "100%", height: "100%" }}>
      {isMultiTreeSession ? (
        <MultiTreeTable
          bookmarkedItems={bookmarkedItems}
          onChangeSortField={changeSortField}
          onBookmark={handleBookmark}
          onRemoveBookmark={handleRemoveBookmark}
        />
      ) : (
        <SingleTreeTable
          bookmarkedItems={bookmarkedItems}
          onChangeSortField={changeSortField}
          onBookmark={handleBookmark}
          onRemoveBookmark={handleRemoveBookmark}
        />
      )}

      <ComparisonTablePagination />
    </Stack>
  );
}

export default ComparisonTable;
