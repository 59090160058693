import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useMultiTreeSession } from "src/hooks/use-multitree-session";
import { Alert, Box, CircularProgress, Stack, Typography } from "@mui/material";
import Dropdown from "src/components/Dropdown";
import { useGetMultiTreeComparisonChartData } from "src/hooks/sessions";
import { getErrorMsg } from "src/utils/Utils";
import {
  defaultPopulationModelKeys,
  MultiTreeFilterType,
  SessionResultsPlotType,
} from "src/utils/types";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function ComparisonHistogram() {
  const chartRef = useRef();
  const { sessionId, comparisonFilters, comparisonFilterFields } =
    useMultiTreeSession();
  const [chartData, setChartData] = useState(undefined);

  const [histogramPlot, setHistogramPlot] = useState([]);

  const {
    mutateAsync: getChartData,
    isLoading,
    error,
  } = useGetMultiTreeComparisonChartData({
    sessionId,
  });

  useEffect(async () => {
    try {
      if (histogramPlot.length == 0) {
        return;
      }

      let allFields = [...defaultPopulationModelKeys];
      histogramPlot.forEach((item) => {
        allFields.push(item.value);
      });

      const { labels, datasets } = await getChartData({
        filterInfo: comparisonFilters,
        fieldsInfo: allFields,
        prepPlotData: SessionResultsPlotType.BAR,
      });

      const data = { labels: labels || [], datasets: datasets || [] };
      setChartData(data);
    } catch (error) {
      console.error("Error fetching chart data:", error);
    }
  }, [JSON.stringify(histogramPlot), JSON.stringify(comparisonFilters)]);

  const labels = useMemo(() => {
    if ((comparisonFilterFields || []).length === 0) {
      return [];
    }

    const filteredFields = comparisonFilterFields.filter(
      (item) =>
        !defaultPopulationModelKeys.includes(item.key) &&
        item.metricType != MultiTreeFilterType.TREE
    );

    if (histogramPlot.length == 0 && filteredFields.length > 0) {
      const firstFilterObj = filteredFields[0];
      setHistogramPlot([
        {
          value: firstFilterObj.isOtherField
            ? `other.${firstFilterObj.key}`
            : firstFilterObj.key,
          label: firstFilterObj.key,
        },
      ]);
    }

    return (filteredFields || []).map((item) => ({
      value: item.isOtherField ? `other.${item.key}` : `${item.key}`,
      label: item.key,
    }));
  }, [JSON.stringify(comparisonFilterFields)]);

  if (!comparisonFilterFields.length > 0)
    return <div className="tile-no-solutions">No solutions found</div>;

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div id="scatter-container">
      {error ? (
        <Box sx={{ width: "100%" }}>
          <Alert severity="error" sx={{ m: "1rem" }}>
            {" "}
            {getErrorMsg(error)}
          </Alert>
        </Box>
      ) : (
        <Fragment>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              flexWrap: "wrap",
              alignItems: "center",
              gap: ".5rem",
              mt: ".25rem",
            }}
          >
            <Dropdown
              value={histogramPlot}
              onChange={setHistogramPlot}
              options={labels.map((item) => {
                return { value: item.value, label: item.label };
              })}
              isSearchable={false}
              multiSelect={true}
            />
          </Box>
          {isLoading ? (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                mt: "3rem",
              }}
            >
              <CircularProgress />
            </Box>
          ) : chartData ? (
            <Fragment>
              {chartData.datasets.length == 0 ? (
                <Alert severity="warning" sx={{ margin: "1rem" }}>
                  No data found for selected fields.
                </Alert>
              ) : (
                <Stack gap={"1.5rem"} mt={"-2rem"}>
                  <Bar
                    id="bar-chart"
                    ref={chartRef}
                    options={options}
                    data={chartData}
                  />
                  <Alert severity="info" sx={{ margin: "1rem" }}>
                    <Stack direction={"row"} gap={"1rem"}>
                      <Typography variant="caption">
                        <b>G: </b>Generation
                      </Typography>
                      <Typography variant="caption">
                        <b>M: </b>Model
                      </Typography>
                    </Stack>
                    <Typography variant="body2">
                      Above barchart shows only models data.
                    </Typography>
                  </Alert>
                </Stack>
              )}
            </Fragment>
          ) : (
            <Box sx={{ padding: ".5rem" }}>
              <Alert severity="info">
                Select axis fields to fill chart data.
              </Alert>
            </Box>
          )}
        </Fragment>
      )}
    </div>
  );
}

export default ComparisonHistogram;
