import { Fragment } from "react";
import { CircularProgress, Stack } from "@mui/material";
import { useGetLogs } from "src/hooks/sessions";
import { useMultiTreeSession } from "src/hooks/use-multitree-session";
import { SESSION_REQ_INTERVAL } from "src/utils/types";

function TrainingLogs() {
  const { sessionId } = useMultiTreeSession();

  const { data: logs, isLoading } = useGetLogs({
    sessionId: sessionId,
    intervalMs: SESSION_REQ_INTERVAL,
  });

  return (
    <Fragment>
      {isLoading ? (
        <Stack alignItems={"center"}>
          <CircularProgress size={24} thickness={5} />
        </Stack>
      ) : (
        <Stack height={"100%"}>
          <pre
            style={{
              whiteSpace: "pre-wrap",
              height: "100%",
              overflow: "auto",
              padding: "0rem 1rem 1.5rem 1rem",
              lineHeight: "1.5",
            }}
          >
            <code>{logs?.data || "No logs found..."}</code>
          </pre>
        </Stack>
      )}
    </Fragment>
  );
}

export default TrainingLogs;
